/* eslint no-console:0 */
/* global uppy_load, uppy_maxFileSize, uppy_maxNumberOfFiles,
          uppy_minNumberOfFiles, uppy_note, uppy_height, uppy_upload_endpoint,
          uppy_target,
          location
*/

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'

const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const ImageEditor = require('@uppy/image-editor')
const XHRUpload = require('@uppy/xhr-upload')

if (typeof uppy_load !== 'undefined' && uppy_load == 'photos') {
  const uppy = new Uppy({
    debug: true,
    autoProceed: false,
    restrictions: {
      maxFileSize: uppy_maxFileSize,
      maxNumberOfFiles: uppy_maxNumberOfFiles,
      minNumberOfFiles: uppy_minNumberOfFiles,
      allowedFileTypes: ['image/*', 'video/*']
    }
  })
  .use(Dashboard, {
    trigger: '.UppyModalOpenerBtn',
    inline: true,
    target: uppy_target,
    replaceTargetContent: true,
    showProgressDetails: true,
    note: uppy_note,
    height: uppy_height,
    autoOpenFileEditor: true,
    proudlyDisplayPoweredByUppy: false,
    metaFields: [
      { id: 'name', name: 'Name', placeholder: 'file name' },
      { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
    ],
    browserBackButtonClose: false,
    locale: {
      strings: {
      // When `inline: false`, used as the screen reader label for the button that closes the modal.
      closeModal: 'Close Modal',
      // Used as the screen reader label for the plus (+) button that shows the “Add more files” screen
      addMoreFiles: 'Add more photos',
      // TODO
      addingMoreFiles: 'Adding more photos',
      // Used as the header for import panels, e.g., “Import from Google Drive”.
      importFrom: 'Import from %{name}',
      // When `inline: false`, used as the screen reader label for the dashboard modal.
      dashboardWindowTitle: 'Uppy Dashboard Window (Press escape to close)',
      // When `inline: true`, used as the screen reader label for the dashboard area.
      dashboardTitle: 'Uppy Dashboard',
      // Shown in the Informer when a link to a file was copied to the clipboard.
      copyLinkToClipboardSuccess: 'Link copied to clipboard.',
      // Used when a link cannot be copied automatically — the user has to select the text from the
      // input element below this string.
      copyLinkToClipboardFallback: 'Copy the URL below',
      // Used as the hover title and screen reader label for buttons that copy a file link.
      copyLink: 'Copy link',
      // Used as the hover title and screen reader label for file source icons, e.g., “File source: Dropbox”.
      fileSource: 'File source: %{name}',
      // Used as the label for buttons that accept and close panels (remote providers or metadata editor)
      done: ' ',
      // TODO
      back: 'Back',
      // Used as the screen reader label for buttons that remove a file.
      removeFile: 'Remove file',
      // Used as the screen reader label for buttons that open the metadata editor panel for a file.
      editFile: 'Edit file',
      // Shown in the panel header for the metadata editor. Rendered as “Editing image.png”.
      editing: 'Editing %{file}',
      // Text for a button shown on the file preview, used to edit file metadata
      edit: 'Edit',
      // Used as the screen reader label for the button that saves metadata edits and returns to the
      // file list view.
      finishEditingFile: 'Finish editing file',
      // TODO
      saveChanges: 'Save changes',
      // Used as the label for the tab button that opens the system file selection dialog.
      myDevice: 'My Device',
      // Shown in the main dashboard area when no files have been selected, and one or more
      // remote provider plugins are in use. %{browse} is replaced with a link that opens the system
      // file selection dialog.
      dropPasteImport: 'Drop photos here, paste, %{browse} or import from',
      // Shown in the main dashboard area when no files have been selected, and no provider
      // plugins are in use. %{browse} is replaced with a link that opens the system
      // file selection dialog.
      dropPaste: 'Drop photos here or %{browse}',
      // TODO
      dropHint: 'Drop your photos here',
      // This string is clickable and opens the system file selection dialog.
      browse: 'browse',
      // Used as the hover text and screen reader label for file progress indicators when
      // they have been fully uploaded.
      uploadComplete: 'Upload complete',
      // TODO
      uploadPaused: 'Upload paused',
      // Used as the hover text and screen reader label for the buttons to resume paused uploads.
      resumeUpload: 'Resume upload',
      // Used as the hover text and screen reader label for the buttons to pause uploads.
      pauseUpload: 'Pause upload',
      // Used as the hover text and screen reader label for the buttons to retry failed uploads.
      retryUpload: 'Retry upload',
      // Used as the hover text and screen reader label for the buttons to cancel uploads.
      cancelUpload: 'Cancel upload',

      // Used in a title, how many files are currently selected
      xFilesSelected: {
        0: '%{smart_count} photo selected',
        1: '%{smart_count} photos selected'
      },
      // TODO
      uploadingXFiles: {
        0: 'Uploading %{smart_count} photo',
        1: 'Uploading %{smart_count} photos'
      },
      // TODO
      processingXFiles: {
        0: 'Processing %{smart_count} photo',
        1: 'Processing %{smart_count} photos'
      },

      // The "powered by Uppy" link at the bottom of the Dashboard.
      // **NOTE**: This string is called `poweredBy2` for backwards compatibility reasons.
      // See https://github.com/transloadit/uppy/pull/2077
      poweredBy2: 'Powered by %{uppy}',

      // @uppy/status-bar strings:
      uploading: 'Uploading',
      complete: 'Complete'
      // ...etc
    }}
  })
  .use(ImageEditor, {
    target: Dashboard,
    cropperOptions: {
      viewMode: 1,
      background: true,
      aspectRatio: 2 / 3,
      autoCropArea: 1,
      height: 1473,
      width: 2207,
    },
    actions: {
      revert: false,
      rotate: true,
      flip: true,
      zoomIn: true,
      zoomOut: true,
      cropSquare: false,
      cropWidescreen: false,
      cropWidescreenVertical: false
    }
  })
  .use(XHRUpload, {
    endpoint: uppy_upload_endpoint,
  })

  uppy.on('complete', result => {
    location.reload();
  })
}

if (typeof uppy_load !== 'undefined' && uppy_load == 'attachments') {
  const uppy = new Uppy({
    debug: true,
    autoProceed: false,
    restrictions: {
      maxFileSize: uppy_maxFileSize,
    }
  })
  .use(Dashboard, {
    trigger: '.UppyModalOpenerBtn',
    inline: true,
    target: uppy_target,
    replaceTargetContent: true,
    showProgressDetails: true,
    note: uppy_note,
    height: uppy_height,
    autoOpenFileEditor: true,
    proudlyDisplayPoweredByUppy: false,
    metaFields: [
      { id: 'name', name: 'Name', placeholder: 'file name' },
      { id: 'caption', name: 'Caption', placeholder: 'describe what the image is about' }
    ],
    browserBackButtonClose: false,
    locale: {
      strings: {
      // When `inline: false`, used as the screen reader label for the button that closes the modal.
      closeModal: 'Close Modal',
      // Used as the screen reader label for the plus (+) button that shows the “Add more files” screen
      addMoreFiles: 'Add more files',
      // TODO
      addingMoreFiles: 'Adding more files',
      // Used as the header for import panels, e.g., “Import from Google Drive”.
      importFrom: 'Import from %{name}',
      // When `inline: false`, used as the screen reader label for the dashboard modal.
      dashboardWindowTitle: 'Uppy Dashboard Window (Press escape to close)',
      // When `inline: true`, used as the screen reader label for the dashboard area.
      dashboardTitle: 'Uppy Dashboard',
      // Shown in the Informer when a link to a file was copied to the clipboard.
      copyLinkToClipboardSuccess: 'Link copied to clipboard.',
      // Used when a link cannot be copied automatically — the user has to select the text from the
      // input element below this string.
      copyLinkToClipboardFallback: 'Copy the URL below',
      // Used as the hover title and screen reader label for buttons that copy a file link.
      copyLink: 'Copy link',
      // Used as the hover title and screen reader label for file source icons, e.g., “File source: Dropbox”.
      fileSource: 'File source: %{name}',
      // Used as the label for buttons that accept and close panels (remote providers or metadata editor)
      done: ' ',
      // TODO
      back: 'Back',
      // Used as the screen reader label for buttons that remove a file.
      removeFile: 'Remove file',
      // Used as the screen reader label for buttons that open the metadata editor panel for a file.
      editFile: 'Edit file',
      // Shown in the panel header for the metadata editor. Rendered as “Editing image.png”.
      editing: 'Editing %{file}',
      // Text for a button shown on the file preview, used to edit file metadata
      edit: 'Edit',
      // Used as the screen reader label for the button that saves metadata edits and returns to the
      // file list view.
      finishEditingFile: 'Finish editing file',
      // TODO
      saveChanges: 'Save changes',
      // Used as the label for the tab button that opens the system file selection dialog.
      myDevice: 'My Device',
      // Shown in the main dashboard area when no files have been selected, and one or more
      // remote provider plugins are in use. %{browse} is replaced with a link that opens the system
      // file selection dialog.
      dropPasteImport: 'Drop files here, paste, %{browse} or import from',
      // Shown in the main dashboard area when no files have been selected, and no provider
      // plugins are in use. %{browse} is replaced with a link that opens the system
      // file selection dialog.
      dropPaste: 'Drop files here, paste or %{browse}',
      // TODO
      dropHint: 'Drop your files here',
      // This string is clickable and opens the system file selection dialog.
      browse: 'browse',
      // Used as the hover text and screen reader label for file progress indicators when
      // they have been fully uploaded.
      uploadComplete: 'Upload complete',
      // TODO
      uploadPaused: 'Upload paused',
      // Used as the hover text and screen reader label for the buttons to resume paused uploads.
      resumeUpload: 'Resume upload',
      // Used as the hover text and screen reader label for the buttons to pause uploads.
      pauseUpload: 'Pause upload',
      // Used as the hover text and screen reader label for the buttons to retry failed uploads.
      retryUpload: 'Retry upload',
      // Used as the hover text and screen reader label for the buttons to cancel uploads.
      cancelUpload: 'Cancel upload',

      // Used in a title, how many files are currently selected
      xFilesSelected: {
        0: '%{smart_count} file selected',
        1: '%{smart_count} files selected'
      },
      // TODO
      uploadingXFiles: {
        0: 'Uploading %{smart_count} file',
        1: 'Uploading %{smart_count} files'
      },
      // TODO
      processingXFiles: {
        0: 'Processing %{smart_count} file',
        1: 'Processing %{smart_count} files'
      },

      // The "powered by Uppy" link at the bottom of the Dashboard.
      // **NOTE**: This string is called `poweredBy2` for backwards compatibility reasons.
      // See https://github.com/transloadit/uppy/pull/2077
      poweredBy2: 'Powered by %{uppy}',

      // @uppy/status-bar strings:
      uploading: 'Uploading',
      complete: 'Complete'
      // ...etc
    }}
  })
  .use(XHRUpload, {
    endpoint: uppy_upload_endpoint,
  })

  uppy.on('complete', result => {
    location.reload();
  })
}
